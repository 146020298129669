<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'profile', params: { realm: this.$route.params.realmId } }">Profile</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="blue darken-2" dark flat dense>
                            <v-app-bar-title>My Session</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0">Expires</p>
                            <p class="mb-0 pb-0">
                                <span>{{ authenticatedExpiresText }}</span>
                                <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>
                            <p class="text-overline mb-0 mt-8">Next refresh</p>
                            <p class="mb-0 pb-0">
                                <span>{{ nextRefreshText }}</span>
                                <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>

                            <template v-if="isAuthenticated">
                                <p class="text-overline mb-0 mt-8">Remember me until</p>
                                <p class="mb-0 pb-0">
                                    <span>{{ rememberMeNotAfterText }}</span>
                                </p>
                                <RememberMeCheckbox v-model="isRememberMeChecked"/>
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Session Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.session, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RememberMeCheckbox from '@/components/RememberMeCheckbox.vue';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
// import { client } from '@/client';
// import { toText } from '@/sdk/time';

export default {
    components: {
        RememberMeCheckbox,
    },
    data: () => ({
        sessionExpiresText: null,
        refreshAfter: null,
        isRememberMeChecked: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        ...mapGetters({
            isAuthenticated: 'isAuthenticated',
        }),
        authenticatedExpiresText() {
            if (typeof this.session.authenticatedNotAfter === 'number' && this.session.authenticatedNotAfter > 0) {
                // return toText(this.session.authenticatedNotAfter - Date.now());
                return new Date(this.session.authenticatedNotAfter).toString();
            }
            return undefined;
        },
        nextRefreshText() {
            if (typeof this.session.nextRefresh === 'number' && this.session.nextRefresh > 0) {
                // return toText(this.session.nextRefresh - Date.now());
                return new Date(this.session.nextRefresh).toString();
            }
            return undefined;
        },
        rememberMeNotAfterText() {
            if (typeof this.session.rememberMeNotAfter === 'number' && this.session.rememberMeNotAfter > 0) {
                return new Date(this.session.rememberMeNotAfter).toString();
            }
            return undefined;
        },
    },
    watch: {
        isRememberMeChecked(newValue, oldValue) {
            // don't trigger when we first set the variable; only trigger when user changes it
            if (typeof oldValue === 'boolean') {
                console.log(`Session.vue: isRememberMeChecked watcher: storing ${newValue}`);
                localStorage.setItem('rememberMe', newValue);
                this.editRememberMe(newValue);
            }
        },
    },
    methods: {
        async getSession() {
            try {
                await this.$store.dispatch('loadSession');
                // this.$store.commit('loading', { getSession: true });
                // const response = await this.$client.main().authn.get();
                // console.log(`got session info: ${JSON.stringify(response)}`);
                // this.refreshAfter = response.duration;

                // console.log(`next refresh ms ${response.refreshAfter} text ${this.nextRefreshText}`);
            } catch (err) {
                console.error('failed to load session', err);
            } finally {
                // this.$store.commit('loading', { getSession: false });
            }
        },
        async editRememberMe(newValue) {
            try {
                this.$store.commit('loading', { editRememberMe: true });
                const request = {
                    remember_me: newValue,
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.editSession(request);
                console.log(`editRememberMe: response ${JSON.stringify(response)}`);
                const { isEdited } = response;
                if (isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
                }
                this.getSession();
            } catch (err) {
                console.error('failed to edit session', err);
            } finally {
                this.$store.commit('loading', { editRememberMe: false });
            }
        },
    },
    mounted() {
        this.getSession();
        const rememberMeStored = localStorage.getItem('rememberMe');
        this.isRememberMeChecked = rememberMeStored === 'true';
    },
};
</script>
